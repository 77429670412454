@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.app {
  display: flex;
  position: relative;
}